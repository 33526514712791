<template>
    <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
            <div class="col-md-5">
                <div class="authincation-content">
                    <div class="row no-gutters">
                        <div
                            class="col-xl-12"
                        ><img class="w-100" src="/assets/images/Perfil_Negro.jpg"/></div>
                        <div class="col-xl-12">
                            <div class="auth-form">
                                <h4 class="text-center mb-4">
                                    Restablece tu contraseña
                                    <br />escribiendo una nueva
                                </h4>
                                <reset-password-form></reset-password-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ResetPasswordForm from './ResetPasswordForm.vue'
export default {
  components: { ResetPasswordForm },
}
</script>

<style>
</style>
