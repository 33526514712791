import { required, helpers, sameAs, minLength, email } from '@vuelidate/validators'
import { computed } from 'vue-demi';
/**
 *  Messages
 *  Este campo solo permite caracteres Númericos.
 *  Este campo es obligatorio.
 */

export default function PasswordEditRules(form){
    const getPassword = computed(() => form.password)
    const rules = {
        token: {
            required: helpers.withMessage('Este campo es obligatorio.', required),
        },
        email: {
            required: helpers.withMessage('Este campo es obligatorio.', required),
            email: helpers.withMessage('Ingrese un correo electrónico valido.', email)
        },
        password: {
            required: helpers.withMessage('Este campo es obligatorio.', required),
            minLength: helpers.withMessage('Este campo debe contener almenos 8 carácteres.', minLength(8)),
        },
        password_confirmation: {
            required: helpers.withMessage('Este campo es obligatorio.', required),
            sameAsPassword: helpers.withMessage('Las contraseñas no coinciden.', sameAs(getPassword)),
            minLength: helpers.withMessage('Este campo debe contener almenos 8 carácteres.', minLength(8)),
        },
    };


    return {
        rules
    }
}
