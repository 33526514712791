<template>
    <form method="POST" class="m-t" role="form" @submit.prevent="onSubmit">
        <div class="form-group text-left" :class="{ error: v.email.$errors.length }">
            <label>
                <i class="fa fa-envelope"></i>
                Correo electrónico
            </label>
            <input
                type="email"
                name="email"
                class="form-control"
                placeholder="example@enforma.com"
                v-model="form.email"
                required
                readonly
            />
            <error-msg :errors="v.email.$errors"></error-msg>
        </div>

        <div class="form-group text-left" :class="{ error: v.password.$errors.length }">
            <label>
                <i class="fa fa-lock"></i>
                Contraseña
            </label>
            <c-input-password v-model:password="form.password" placeholder="* * * * *" toggle></c-input-password>
            <error-msg :errors="v.password.$errors"></error-msg>
        </div>

        <div
            class="form-group text-left"
            :class="{ error: v.password_confirmation.$errors.length }"
        >
            <label>
                <i class="fa fa-lock"></i>
                Confirmar tu contraseña
            </label>
            <c-input-password
                v-model:password="form.password_confirmation"
                placeholder="* * * * *"
                toggle
            ></c-input-password>
            <error-msg :errors="v.password_confirmation.$errors"></error-msg>
        </div>

        <div>
            <button
                type="submit"
                class="btn btn-primary block full-width m-b d-flex justify-content-center align-items-center w-100"
                :class="{ 'not-allowed': formHasErrors || loading }"
                :disabled="formHasErrors || loading"
            >
                <c-spinner :loading="loading" />
                <span class="ml-1">Restablecer contraseña</span>
            </button>
        </div>
    </form>
</template>

<script>
import { ref } from '@vue/reactivity'
import { reactive } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'
import { computed } from '@vue/runtime-core';
import useVuelidate from "@vuelidate/core";
import CInputPassword from '../../components/inputs/CInputPassword.vue'
import ErrorMsg from '../../components/modals/ErrorMsg.vue';
import CSpinner from '../../components/spinners/CSpinner.vue';
import ResetPasswordRules from "@/rules/ResetPasswordRules.js";
import { passwordRecoveryUpdatePasswordService } from '../../services/auth/authServices';
export default {
    components: { CInputPassword, ErrorMsg, CSpinner },

    setup() {
        const route = useRoute()
        const form = reactive({
            token: route.params.token,
            email: route.params.email,
            password: '',
            password_confirmation: '',
        })

        const { rules } = ResetPasswordRules(form);
        const v$ = useVuelidate(rules, form, { $autoDirty: true });

        const loading = ref(false)
        const router = useRouter()
        const onSubmit = async () => {
            const isFormCorrect = await v$.value.$validate();
            if (!isFormCorrect) return;

            loading.value = true
            const response = await passwordRecoveryUpdatePasswordService(form)
            if (response.status === 200) {
                Swal.fire(
                    "Hecho",
                    response.message,
                    "success"
                )
                .then(() => {
                    router.push({name: 'Login'})
                })
            } else {
                Swal.fire(
                    "Error!",
                    response.message,
                    "error"
                )
            }
            loading.value = false
        }

        const formHasErrors = computed(() => {
            return v$.value.$silentErrors.length > 0
        })

        return {
            form,
            onSubmit,
            loading,
            formHasErrors,
            v: v$,
        }
    }

}
</script>

<style>
.not-allowed {
    cursor: not-allowed;
}
</style>
